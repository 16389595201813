@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./assets/font/stylesheet.css");
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  font-family: "Geogrotesque Cyr SemiBold", sans-serif;
  background-color: #1a1d29;
}

html {
  overflow-x: hidden;
  scroll-padding-top: 9rem;
  /* scroll-behavior: smooth; */
}

html::-webkit-scrollbar {
  width: 0.6rem;
}

html::-webkit-scrollbar-track {
  background: #1a1d29;
}

html::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: rgba(203, 215, 255, 0.08);
}

.uppercase {
  text-transform: uppercase;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
